angular.module("common")
    .factory('userInvitationModal', ["$uibModal", function ($uibModal) {
    	// TODO: refactor as config object with these props (and everywhere called)
        function open(
        			modalTitle, 
        			isEdit, 
        			availableLicenses, 
        			organizationId, 
        			availableRoles, 
        			selectedRoles, 
        			showRoleSelection, 
        			requireOneRole,
        			user,
        			linkConfig, // {label: "text to show", callback: function}
        			emailOnly // don't require name or license (used to invite AFL support)
        ) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/templates/common/userInvitationModal.html",
                controller: "UserInvitationModalCtrl",
                resolve: {
                    modalTitle: function () {
                        return modalTitle;
                    },
                    isEdit: function () {
                        return isEdit;
                    },
                    availableLicenses: function () {
                        return availableLicenses;
                    },
                    organizationId: function () {
                        return organizationId;
                    },
                    availableRoles: function () {
                        return availableRoles;
                    },
                    selectedRoles: function () {
                        return selectedRoles;
                    },
                    showRoleSelection: function () {
                        return showRoleSelection;
                    },
                    requireOneRole: function () {
                        return requireOneRole;
                    },
                    user: function () {
                        return user;
                    },
                    linkConfig: function () {
                    	return linkConfig;
                    },
                    emailOnly: function () {
                    	return emailOnly;
                    }
                }
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("UserInvitationModalCtrl",
    ["$scope", "$uibModalInstance", "aerosApi", "modalTitle", "isEdit", "availableLicenses", "organizationId", "availableRoles", "selectedRoles", "showRoleSelection", "requireOneRole", "user", "linkConfig", "emailOnly",
        function ($scope, $uibModalInstance, aerosApi, modalTitle, isEdit, availableLicenses, organizationId, availableRoles, selectedRoles, showRoleSelection, requireOneRole, user, linkConfig, emailOnly) {
            $scope.formValid = false;
    		$scope.isEdit = isEdit;
            $scope.availableRoles = availableRoles;
            $scope.chosenLicenseKey = "";
            $scope.availableLicenses = availableLicenses;
            $scope.formdata = {
                requireOneRole: function () {
                    if (requireOneRole) {
                        return !$scope.user.roles.length;
                    } else {
                        return false;
                    }
                }
            };
            if (typeof user === "undefined") {
                $scope.user = {
                    organizationId: organizationId,
                    roles: _.map(selectedRoles, "id") || []
                };
            } else {
                $scope.user = user;
                $scope.formdata.disableInputs = true;
            }
            $scope.askRoles = showRoleSelection;
            $scope.modalTitle = modalTitle;
            if (linkConfig && linkConfig.label && linkConfig.callback) {
	            $scope.linkLabel = linkConfig.label;
	            $scope.followLink = function () {
	            	if ($scope.formValid) {
	            		$scope.save();
	            	} else {
	            		$scope.cancel();
	            	}
	            	linkConfig.callback();
	            };
            }
            $scope.emailOnly = emailOnly;
            
            // pre-select highest rank passed in
            $scope.selectedRoleRank = _.map(_.take(_.sortBy(selectedRoles, function (role) {
                return role.rank;
            })), "rank");

            // only passing highest permissions level selected now, see AEROS-894
            $scope.toggleRole = function (role) {
                $scope.user.roles = [role.id];
                $scope.selectedRoleRank = role.rank;
            };
            $scope.showChecked = function (role) {
                return role.rank >= $scope.selectedRoleRank;
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss();
            };

            $scope.save = function () {
                $scope.user.licenseKey = $scope.chosenLicenseKey.id;
                $uibModalInstance.close($scope.user);
            };
        }]);